import {getBearerToken} from "./Authorization";


/**
 * Creates product input object
 * @returns product object
 */
function createProductObject() {

    let productObj = {}

    $(".price-list").each((index, el) => {
        const prodid = $(el).find(".articleitem").data("prodid")
        const priceHash = $(el).find(".mpp-price-item").data("price-hash")
        const insuranceHash = $(el).find(".mpp-price-item").data("insurance-hash")

        if(typeof prodid !== "undefined") {
            productObj[prodid] = {
                "id": prodid,
                "price": priceHash,
                "insurance": insuranceHash
            }
        }
    })

    return productObj
}

function getDecodeData(){
    return atob($("body").data("calcdata"));
}

/**
 * Re-inserts calculated prices into price template
 * @param responseObject
 */
function insertCalculatedPrices(responseObject) {
    for(let key in responseObject) {
        if(responseObject.hasOwnProperty(key)){
            let product = $(".price-list").find(".articleitem[data-prodid=" + key + "] .value");
            for(let childkey in responseObject[key]){
                $(product).html(new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(responseObject[key][childkey].leasing_net))
            }
        }
    }
}

/**
 * Creates input object
 * @returns inputObject
 */
function createInputObject(inputData) {
    return {
        "salary": inputData,
        "products": createProductObject()
    }
}

/**
 * Gets calculated prices from api based on input
 * @param inputObject
 */
function getCalculatedPrices(inputObject) {
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2NhbGN1bGF0b3IubWVpbm1wcC5kZS9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTcwNzgzMDMxNiwibmJmIjoxNzA3ODMwMzE2LCJqdGkiOiJmeDZOTWFQM3dnOFlyd2pnIiwic3ViIjo4LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.UT3_MJ2F-N3dQqyumpErA6iJ9fNOXPXbkO_1XxGr5nk'
    const url = 'https://calculator.meinmpp.de/api/calculate'

    try{
         $.ajax({
            url: url,
            type: "post",
            headers: {
                "Authorization": "Bearer " + token,
                "Accept": "application/x.calculator.v1+json",
                "Cache-Control": "no-cache"
            },
            data: inputObject
        }).done((response) => {
             insertCalculatedPrices(response.products_calculated)
             removeAnimation(".loader");
             removeAnimation(".price-text");
             removeAnimation(".ordernow__button-text");
             removeAnimation(".costs");
         })
    } catch(error) {console.error(error)}
}

function removeAnimation(el){
    $(el).removeClass("isloading");
}


export {createInputObject, getCalculatedPrices, insertCalculatedPrices, getDecodeData}

